import { RichText } from "prismic-reactjs";
import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { isMobile } from "../../../utils";
import "../../../styles/components/Invest/Banner/style.scss";

const Banner = ({ content }) => {
  const bannerText = content?.data?.section_1;
  const showImage = content?.data?.invest_show_image;
  const showImageMobile = content?.data?.invest_show_image_mobile;
  const imageUrl = content?.data?.invest_image?.url;
  const imageWidth = content?.data?.invest_image?.dimensions?.width;
  const imageHeight = content?.data?.invest_image?.dimensions?.height;

  if (!content) {
    return null;
  }
  return (
    <Row className="justify-content-center mx-auto mw-1440 investmen_banner">
      <Col md={6} className="pr-20">
        <div className="banner1 rich-text">
          <RichText render={bannerText} />
        </div>
      </Col>
      {!isMobile() && showImage && (
        <Col className="banner-img pl-20" md={6}>
          <Image
            src={imageUrl}
            alt=""
            fluid
            width={imageWidth}
            height={imageHeight}
          />
        </Col>
      )}
      {isMobile() && showImageMobile && (
        <Col className="banner-img" md={6}>
          <Image
            src={imageUrl}
            alt=""
            fluid
            width={imageWidth}
            height={imageHeight}
          />
        </Col>
      )}
      {!isMobile() && (
        <Col md={12}>
          <hr className="section-end" />
        </Col>
      )}
    </Row>
  );
};

export default Banner;
