import React, { useEffect, useState } from "react";
import * as prismic from "@prismicio/client";
import { isMobile } from "../utils";
import Slider from "react-slick";
import Layout from "../components/Layout";
import Banner from "../components/Invest/Banner";
import InvestPageContent from "../components/Invest/PageContent";
import PreFooter from "../components/PreFooter";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/pages/invest.scss";

const Invest = () => {
  const [investDoc, setInvestDoc] = useState();
  const [brandsDoc, setBrandsDoc] = useState();

  useEffect(() => {
    async function fetchData() {
      const investHeaderResponse = await prismicClient().getSingle("invest");
      setInvestDoc(investHeaderResponse);

      const brandResponse = await prismicClient().get({
        predicates: prismic.predicates.at(
          "document.type",
          "opal_fund_partners"
        ),
        orderings: {
          field: "document.first_publication_date",
          direction: "desc",
        },
      });
      setBrandsDoc(brandResponse);
    }
    fetchData();
  }, []);

  const settings = {
    className: "invest-slider",
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 12000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const opalFundPartners = brandsDoc?.results?.sort((a, b) => {
    return a.data.brand_image_title[0].text.localeCompare(b.data.brand_image_title[0].text);
  }) ?? [];

  return brandsDoc ? (
    <Layout>
      <div style={{ minHeight: "calc(100vh - 400px)" }}>
        <Banner content={investDoc} />
        {!isMobile() &&
          opalFundPartners.map(
            (item, index) =>
              item?.data?.brand_show && (
                <InvestPageContent
                  key={index}
                  heading={item?.data?.brand_heading_1[0]?.text}
                  image={item?.data?.brand_image?.url}
                  description={item?.data?.brand_description_1[0]?.text}
                  bottomHeading={item?.data?.brand_amount_card[0]}
                  threeDots={item?.data?.brand_3_dots}
                  navigation={`/brand/${item?.uid}`}
                />
              )
          )}
        {isMobile() && brandsDoc && (
          <Slider {...settings}>
            {opalFundPartners.map(
              (item, index) =>
                item?.data?.brand_show && (
                  <InvestPageContent
                    key={index}
                    heading={item?.data?.brand_heading_1[0]?.text}
                    image={item?.data?.brand_image?.url}
                    description={item?.data?.brand_description_1[0]?.text}
                    bottomHeading={item?.data?.brand_amount_card[0]}
                    threeDots={item?.data?.brand_3_dots}
                    navigation={`/brand/${item?.uid}`}
                  />
                )
            )}
          </Slider>
        )}
        <PreFooter
          className="invest-pre-footer"
          prefooterText={investDoc?.data?.prefooter}
          buttonText={investDoc?.data?.prefooter_button_text[0]?.text}
          buttonLink={investDoc?.data?.prefooter_button_link?.url}
        />
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Invest;
