import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { isMobile } from "../../../utils";
import { navigate } from "gatsby";
import "../../../styles/components/Invest/PageContent/style.scss";

function add3Dots(string, limit) {
  var dots = "...";
  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
}

const InvestPageContent = ({
  heading,
  image,
  description,
  bottomHeading,
  navigation,
  threeDots,
}) => {
  const handleNavigate = () => {
    navigate(navigation);
  };
  return (
    <Row className="justify-content-center mx-auto mw-1440 investment_content">
      <Col className="invest-content-img pr-20" sm={6}>
        <Image src={image} alt="" fluid className="img-margin" />
      </Col>
      <Col sm={6} className="pl-20">
        <div className="invest-container">
          <h3 className="invest-heading">{heading}</h3>
          <p className="invest-decription">
            {description && threeDots
              ? add3Dots(description, threeDots)
              : description}
          </p>
          <div className="invest-separator"></div>

          {bottomHeading && bottomHeading?.spans?.length ? (
            <p className="font-24">
              {bottomHeading &&
                bottomHeading?.text?.substring(
                  0,
                  bottomHeading && bottomHeading?.spans[0]?.start
                )}
              <span className="blue-dark-text">
                <b>
                  {bottomHeading &&
                    bottomHeading?.text?.substring(
                      bottomHeading?.spans[0]?.start,
                      bottomHeading?.spans[0]?.end
                    )}
                </b>
              </span>
              {bottomHeading &&
                bottomHeading?.text?.substring(
                  bottomHeading && bottomHeading?.spans[0]?.end
                )}
            </p>
          ) : (
            <p className="font-24">{bottomHeading?.text}</p>
          )}

          <div>
            {/* <a href="/brand" className="text-blue"> */}
            <h3 className="learn-more" onClick={handleNavigate}>
              Learn more
            </h3>
            {/* </a> */}
          </div>
        </div>
      </Col>
      {!isMobile() && (
        <Col sm={12}>
          <hr className="section-end" />
        </Col>
      )}
    </Row>
  );
};

export default InvestPageContent;
